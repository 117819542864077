








































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { State, Action, Mutation } from "vuex-class";
import { IParteCabeceraAprobacion } from "@/store/Parte/types";
import {
  ParteModelConsulta,
  ParteQueryParametro,
  ObtenerPartePendiente,
  ParteDetalleModel,
  AprobarParte,
  AprobacionRechazarModal,
  RechazarParte,
  DescargarPdf,
  ObtenerParteDetalle,
  ObtenerParteDetalleImagen,
} from "@/entidades/Plantacion/Partes/Consulta/ParteModelConsulta";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";
import ResponseGenerico from "@/entidades/ResponseGenerico";
@Component({
  components: {
    Confirmacion: () => import("@/components/Confirmacion.vue"),
    tabla: () => import("@/components/General/TablaGeneralFinal.vue"),
    cardImagen: () => import("@/components/General/VisualizarImagen.vue"),
  },
})
export default class extends Vue {
  @State("usuarioId", { namespace: "authModule" }) usuarioId!: string;
  @Action("cargarPartesPendientes", { namespace: "parteModule" })
  cargarPartesPendientes!: Function;
  @Action("enviarAprobacion", { namespace: "parteModule" })
  enviarAprobacion!: Function;
  @Action("changeLoading", { namespace: "sistemaModule" })
  changeLoading!: Function;
  @Action("changeAlerta", { namespace: "sistemaModule" })
  changeAlerta!: Function;
  dialogAprobar: boolean = false;
  dialogRechazar: boolean = false;
  tab: number = 0;
  tabs: Array<number> = [0, 1];
  componenteParte: string = "";
  parametroFiltro: ParteQueryParametro = new ParteQueryParametro();
  componentDialog = "";
  motivoRechazo: string = "";
  search: string = "";
  selected: [] = [];
  headers: any[] = [
    {
      text: "Fecha",
      align: "start",
      sortable: true,
      value: "fecha",
    },
    {
      text: "# Parte",
      align: "start",
      sortable: true,
      value: "parteIdCustomer",
    },
    {
      text: "Hacienda",
      align: "start",
      sortable: true,
      value: "haciendaName",
    },
    {
      text: "Mayordomo",
      align: "start",
      sortable: false,
      value: "mayordomo",
    },
    { text: "Acciones", align: "center", value: "actions", sortable: false },
  ];

  headers2: any[] = [
    {
      text: "Fecha",
      align: "start",
      sortable: true,
      value: "fecha",
    },
    {
      text: "# Parte",
      align: "start",
      sortable: false,
      value: "parteIdCustomer",
    },
    {
      text: "Hacienda",
      align: "start",
      sortable: false,
      value: "haciendaName",
    },
    {
      text: "Mayordomo",
      align: "start",
      sortable: false,
      value: "mayordomo",
    },
    {
      text: "Rechazo",
      align: "start",
      sortable: false,
      value: "usuarioRechazo",
    },
    {
      text: "Motivo",
      align: "start",
      sortable: false,
      value: "motivo",
    },

    { text: "Acciones", align: "center", value: "acciones", sortable: false },
  ];

  cabeceraDetalles: any[] = [
    {
      text: "Labor",
      align: "start",
      sortable: false,
      value: "laborName",
    },
    {
      text: "Lote",
      align: "start",
      sortable: false,
      value: "loteName",
    },
    {
      text: "Cuadrilla",
      align: "start",
      sortable: false,
      value: "cuadrillaName",
    },
    {
      text: "Cantidad",
      align: "end",
      sortable: false,
      value: "cantidad",
    },
    {
      text: "Plantulas",
      align: "end",
      sortable: false,
      value: "cantidadPlantula",
    },
    {
      text: "Precio",
      align: "end",
      sortable: false,
      value: "precio",
    },
    { text: "Acciones", align: "center", value: "actions", sortable: false },
  ];

  listadoPartes: Array<ParteModelConsulta> = [];
  mensaje = "";
  detalleParte = new Array<ParteDetalleModel>();
  parteId = 0;
  mostrandoDetalles = false;
  decidiendo = false;
  aprobado = false;
  parteTmp?: IParteCabeceraAprobacion;
  Detallando = false;
  Motivo = "";
  modalConfirmacion: boolean = false;
  messageConfirmacion: string = "";
  propsEnviar: any = {};
  imagenComponent: string = "";
  imagenModal: boolean = false;
  imagenString: string = "";
  itemTmp: ParteModelConsulta | undefined = undefined;
  async descargarPdf(parte: ParteModelConsulta) {
    try {
      this.changeLoading(new Loading(true, "Descargando pdf"));
      var response = await DescargarPdf(parte.parteId!);
      let blob = new Blob([response], { type: "application/pdf" });
      var url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, "Descargando pdf"));
    }
  }
  async abrirVerDetalles(parte: ParteModelConsulta) {
    try {
      this.changeLoading(new Loading(true, "Cargando informacion"));
      var response = await ObtenerParteDetalle(parte.parteId!);
      if (response.isSuccess) {
        this.detalleParte = response.isResult;
        this.mostrandoDetalles = true;
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 1000));
      }
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  async ObtenerParte() {
    try {
      this.changeLoading(new Loading(true, "Cargando informacion"));
      let rsp = await ObtenerPartePendiente(this.parametroFiltro);
      if (rsp.isSuccess == true) {
        this.listadoPartes = rsp.isResult;
        console.log("obteniene el listao de partes", this.listadoPartes);
      } else {
        this.changeAlerta(new Alerta(rsp.isMessage, true, "danger", 1000));
        this.listadoPartes = [];
      }
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  abrilModalParteAprobar(item: ParteModelConsulta) {
    try {
      if (this.selected.length <= 0) {
        this.changeAlerta(
          new Alerta(
            "Debe seleccionar al menos un parte para poder realizar la aprobación",
            true,
            "danger",
            1000
          )
        );
        return;
      }
      var tipo = 1;
      this.messageConfirmacion = `Esta seguro de aprobar los partes: ${this.selected.length}`;
      this.componentDialog = "ConfirmacionDialog";
      this.dialogAprobar = true;
      this.itemTmp = item;
      // this.propsEnviar ={'open' : this.modalConfirmacion,'message' :this.messageConfirmacion,
      //     'methodDialog':this.confirmacionDialogResponse,'objeto':item,
      //     'tipo': tipo};
    } catch (error) {
      console.log(error);
    }
  }

  abrilModalParteRechazar(item: ParteModelConsulta) {
    try {
      console.log(item);
      this.itemTmp = item;
      this.messageConfirmacion = `Esta seguro de rechazar el parte ${item.parteIdCustomer}?`;
      this.componentDialog = "ConfirmacionDialog";
      this.dialogRechazar = true;
      // this.propsEnviar ={'open' : this.modalConfirmacion,'message' :this.messageConfirmacion,
      //     'methodDialog':this.confirmacionDialogResponse,'objeto':item,
      //     'tipo': tipo};
    } catch (error) {
      console.log(error);
    }
  }

  async rechazarResponse() {
    try {
      this.changeLoading(new Loading(true, "Procesando....."));
      if (this.itemTmp != undefined) {
        var aproParte: AprobacionRechazarModal = new AprobacionRechazarModal(
          this.itemTmp.parteId!,
          this.usuarioId,
          this.itemTmp.parteAprobacionId!,
          false,
          this.motivoRechazo,
          this.itemTmp.nivelActual,
          this.itemTmp.nivelMaximo
        );
        var rsp = await RechazarParte(aproParte);
        if (rsp.isSuccess) {
          this.dialogRechazar = false;
          await this.changeAlerta(
            new Alerta(rsp.isMessage, true, "primary", 3000)
          );
          await this.ObtenerParte();
        } else {
          this.changeAlerta(new Alerta(rsp.isMessage, true, "danger", 3000));
        }
      }
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }
  async confirmacionDialogResponse() {
    try {
      this.changeLoading(new Loading(true, "Procesando....."));
      if (this.itemTmp != undefined) {
        //validamos con el tipo enviar aprobacion
        let partesAprobar: AprobacionRechazarModal[] = [];
        this.selected.forEach((x: any) => {
          var aproParte: AprobacionRechazarModal = new AprobacionRechazarModal(
            x.parteId!,
            this.usuarioId,
            x.parteAprobacionId!,
            true,
            "",
            x.nivelActual,
            x.nivelMaximo
          );
          partesAprobar.push(aproParte);
        });
        // var aproParte: AprobacionRechazarModal = new AprobacionRechazarModal(
        //   this.itemTmp.parteId!,
        //   this.usuarioId,
        //   this.itemTmp.parteAprobacionId!,
        //   true,
        //   "",
        //   this.itemTmp.nivelActual,
        //   this.itemTmp.nivelMaximo
        // );
        var rsp = await AprobarParte(partesAprobar);
        if (rsp.isSuccess) {
          this.dialogAprobar = false;
          this.changeAlerta(new Alerta(rsp.isMessage, true, "primary", 3000));
          await this.ObtenerParte();
        } else {
          await this.changeAlerta(
            new Alerta(rsp.isMessage, true, "danger", 3000)
          );
        }
      }
    } catch (error) {
      this.changeAlerta(new Alerta(String(error), true, "danger", 3000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }
  salir() {
    this.dialogAprobar = false;
    this.dialogRechazar = false;
    this.itemTmp = undefined;
  }

  async visualizarImagen(item: ParteDetalleModel) {
    try {
      this.changeLoading(new Loading(true, "Cargando informacion"));
      var response = await ObtenerParteDetalleImagen(item.parteDetalleId!);
      console.log(response);
      if (response.isSuccess) {
        this.imagenComponent = "cardImagen";
        this.imagenString = response.isMessage;
        this.imagenModal = true;
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 1000));
      }
    } catch (error) {
      //@ts-ignore
      this.changeAlerta(new Alerta(error, true, "danger", 1000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
    if (item.imagen == "") {
      this.changeAlerta(
        new Alerta(
          "No se puede visualizar imagen, no se encontro la imagen",
          true,
          "danger",

          3000
        )
      );
      return;
    }
  }
  //WATCH
  // @Watch('tab')
  // filtroBusqueda(valueNew:number,valueOld:number)
  // {
  //     if(valueNew == 0)
  //     {
  //         this.parametroFiltro.usuarioId= this.usuarioId;
  //         this.parametroFiltro.tipo = false;
  //     }
  //     else if(valueNew == 1)
  //     {
  //         this.parametroFiltro.usuarioId= this.usuarioId;
  //         this.parametroFiltro.tipo = true;
  //     }
  //     this.listadoPartes =[];
  //     this.ObtenerParte();
  // }

  //computed

  mounted() {
    this.parametroFiltro.usuarioId = this.usuarioId;
    this.parametroFiltro.tipo = false;
    this.ObtenerParte();
  }
}
